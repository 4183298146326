export default [
  {
    text: "Actions",
    value: "actions",
    cellClass: "px-2",
    class: "px-2",
    align: "left",
    sortable: false,
    width: "64px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Jira",
    icon: "fab fa-jira mr-1",
    value: "jiraCode",
    cellClass: "px-2",
    class: "px-2",
    width: "92px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  {
    text: "Client",
    value: "client",
    width: "72px",
    cellClass: "px-2",
    class: "px-2",
    order: 2,
    hidable: false,
    hidden: false,
  },
  { text: "Name", value: "name", width: "250px", order: 3, hidable: false, hidden: false },
  { text: "Status", value: "status", width: "150px", order: 4, hidable: true, hidden: false },
  {
    text: "Spaces",
    value: "spaceCount",
    sortable: true,
    width: "92px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Equips",
    value: "equipmentCount",
    sortable: true,
    width: "92px",
    order: 6,
    hidable: true,
    hidden: false,
  },
  {
    text: "Total Cost",
    value: "totalMsrpPrice",
    sortable: false,
    width: "120px",
    order: 7,
    hidable: true,
    hidden: false,
  },
  {
    text: "CPLX",
    fullText: "Complexity",
    value: "projectComplexity",
    cellClass: "px-2",
    class: "px-2",
    width: "92px",
    order: 8,
    hidable: true,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 9,
    hidable: true,
    hidden: true,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    order: 10,
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "130px",
    order: 11,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
    order: 12,
    hidable: true,
    hidden: false,
  },
];
